import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { isBlogNotFound } from ':lib/_i18n';
import { LOCALES } from '../../locales';

const isInternalLink = (href) => {
  return (
    href && /^(\/|\w)/.test(href) && !/rippling\.com|^http|^https/.test(href)
  );
};
const getHrefPathname = (href) => {
  return href.replace(/(.*?)rippling\.com/, '');
};
const shouldUseLocale = (href, locale) => {
  if (!href) {
    return true;
  }
  return !isBlogNotFound(getHrefPathname(href), locale);
};

const LinkHelper = (props) => {
  const {
    children,
    className,
    href,
    target,
    rel,
    simulateClick,
    anchorProps,
    onClick,
    data,
    locale,
    onTransitionEnd,
    id,
  } = props;

  const router = useRouter();
  const cookies = new Cookies();
  const cookiedSiteLocale = cookies.get('NEXT_LOCALE');
  // we dont want to accidentally opt users in/out without end-to-end middleware runs
  const optPaths = cookies.get('opt_paths');
  const enablePrefetch = optPaths && !optPaths.includes(href);
  if (data && onClick) {
    data.onClick = onClick;
  }
  const localeToUse = shouldUseLocale(href, locale)
    ? locale || cookiedSiteLocale
    : LOCALES.EN_US;

  if (simulateClick) {
    let press;

    return (
      <div
        onMouseDown={() => {
          press = new Date().getTime();
        }}
        onMouseUp={() => {
          if (new Date().getTime() - press < 200) {
            router.push(href);
          }
        }}
        className={className}
        onTransitionEnd={onTransitionEnd}
        {...anchorProps}
        {...data}
      >
        {children}
      </div>
    );
  } else if (isInternalLink(href)) {
    return (
      <Link
        href={href}
        locale={localeToUse}
        prefetch={enablePrefetch}
        className={className}
        onClick={onClick}
        onTransitionEnd={onTransitionEnd}
        target={target}
        id={id}
        {...anchorProps}
        {...data}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <a
        href={href}
        className={className}
        target={target}
        onClick={onClick}
        rel={rel}
        onTransitionEnd={onTransitionEnd}
        id={id}
        {...anchorProps}
        {...data}
      >
        {children}
      </a>
    );
  }
};

export default LinkHelper;
