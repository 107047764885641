import { NextRequest } from 'next/server';
import { isLocaleString, isNonEmptyString } from ':helpers/validation-helpers';
import { LOCALES, LOCALES_LIST } from '../locales';

const NON_LOCALIZED_PAGE_PATHS = ['blog'];

const NON_LOCALIZED_PATHS_REGEXPS = NON_LOCALIZED_PAGE_PATHS.map(
  (rootPath: string) => {
    const pattern = `^/${rootPath}$|^/${rootPath}/(.*)`;
    return new RegExp(pattern);
  }
);

export const localeIsAllowedOnSite = (locale: string) => {
  return LOCALES_LIST.includes(locale);
};

export const getLocaleSubFields = (locale: string) => {
  const [language, country] = locale.split('-');

  return { language, country };
};

const makeLocaleFromSubFields = ({
  language,
  country,
}: {
  language: string;
  country: string;
}) => {
  return `${language.toLowerCase()}-${country.toUpperCase()}`;
};

/**
 * @param headerValue - 'en-ca,en;q=0.8,en-us;q=0.6,de-de;q=0.4,de;q=0.2'
 */
export const getLocaleFromAcceptLanguageHeaderOrNull = (
  headerValue: string
) => {
  if (!isNonEmptyString(headerValue)) {
    return null;
  }

  const lowercaseLangVal = headerValue.toLowerCase();
  // en-ca (first entry should be strongest)
  const lowercaseLocaleMatch =
    lowercaseLangVal.match(/[a-z]{2}-[a-z]{2}.*?/)?.[0] || null;

  if (!isLocaleString(lowercaseLocaleMatch)) {
    return null;
  }

  // { language, country }
  const localeSubFields = getLocaleSubFields(lowercaseLocaleMatch);
  // en-CA
  return makeLocaleFromSubFields(localeSubFields);
};

/**
 * @param headerValue - 'en-ca,en;q=0.8,en-us;q=0.6,de-de;q=0.4,de;q=0.2'
 */
export const getLangFromAcceptLanguageHeaderOrNull = (headerValue: string) => {
  if (!isNonEmptyString(headerValue)) {
    return null;
  }
  // if no locale was found, attempts to get it from a language value (en)
  const lowercaseLangVal = headerValue.toLowerCase();
  // en (first entry should be strongest)
  return lowercaseLangVal.match(/[a-z]{2}.*?/)?.[0] || null;
};

export const getLocaleFromRequestOrNull = (req: NextRequest): string | null => {
  const acceptLanguageHeader = req.headers.get('accept-language');
  const headerPreferredLocale = getLocaleFromAcceptLanguageHeaderOrNull(
    acceptLanguageHeader
  );
  // if the preferred header locale is available on the site, lets use that.
  if (
    isLocaleString(headerPreferredLocale) &&
    localeIsAllowedOnSite(headerPreferredLocale)
  ) {
    return headerPreferredLocale;
  }

  // otherwise, we try to peice our own together
  const headerLanguage = getLangFromAcceptLanguageHeaderOrNull(
    acceptLanguageHeader
  );
  const reqCountry = req.geo.country;
  // if either are missing, its not worth guessing wrong...
  if (!isNonEmptyString(headerLanguage) || !isNonEmptyString(reqCountry)) {
    return null;
  }
  // return an accurate guess if we have both.
  return makeLocaleFromSubFields({
    language: headerLanguage,
    country: reqCountry,
  });
};

export const localizedBlogs = ['en-US', 'en-AU', 'nl-NL', 'fr-FR', 'de-DE'];

export const isLocalizedBlog = (locale) => {
  return localizedBlogs.includes(locale);
};

export const isBlogNotFound = (pathname: string, requestedLocale: string) => {
  const pattern = /^\/blog$|^\/blog\/(.*)/;

  if (pattern.test(pathname) && !localizedBlogs.includes(requestedLocale)) {
    return true;
  }

  return false;
};

const testEnUSPagePath = (pathname: string) => {
  return NON_LOCALIZED_PATHS_REGEXPS.some((reg) => reg.test(pathname));
};

export const isUSPathOnly = (pathname: string) => {
  // Redirect all non en-US traffic to en-US
  if (testEnUSPagePath(pathname)) {
    return true;
  }

  return false;
};

export const shouldForceEnUsLocale = (
  pathname: string,
  requestedLocale: string
) => {
  if (
    isUSPathOnly(pathname) &&
    requestedLocale !== LOCALES.EN_US &&
    requestedLocale !== LOCALES.EN_AU &&
    requestedLocale !== LOCALES.NL_NL &&
    requestedLocale !== LOCALES.FR_FR &&
    requestedLocale !== LOCALES.DE_DE
  ) {
    return true;
  }

  return false;
};

export const getAssumedUserLocaleOrEnUs = (req: NextRequest): string => {
  const reqLocal = req.nextUrl.locale;
  const cookieLocale = req.cookies.get('NEXT_LOCALE')?.value;

  // Only use locale logic if entering on en-US site
  if (reqLocal !== LOCALES.EN_US) {
    return reqLocal;
  }
  // no need to do extra work if its cookied and valid...
  if (isLocaleString(cookieLocale) && localeIsAllowedOnSite(cookieLocale)) {
    return cookieLocale;
  }
  // attempt to get the locale from the accept language header
  const assumedLocale = getLocaleFromRequestOrNull(req);
  if (isLocaleString(assumedLocale) && localeIsAllowedOnSite(assumedLocale)) {
    return assumedLocale;
  }
  // if this is the first time a user visits and we can predict their locale,
  // it would be predictable if we assumed the first link locale as thier setting...
  if (isLocaleString(reqLocal) && localeIsAllowedOnSite(reqLocal)) {
    return reqLocal;
  }
  // if all else fails, lets assume en-US
  return LOCALES.EN_US;
};

export const shouldRedirectUserRequest = (
  requestedLocale: string,
  userLocale: string | null,
  pathname: string
) => {
  if (!userLocale) {
    return false;
  }

  // Don't do an i18n redirect for US only paths
  if (isUSPathOnly(pathname)) {
    return false;
  }

  // we only want to redirect external locale traffic
  // en-FR users should be able to see en-GB if they click an en-GB link
  // en-FR users should be redirected if they click on a en-US link
  const requestedPageIsEnUS = requestedLocale === LOCALES.EN_US;

  return (
    requestedPageIsEnUS &&
    requestedLocale !== userLocale &&
    localeIsAllowedOnSite(userLocale)
  );
};

export const phrasePrefixes = {
  page: '',
  post: 'blog/',
  events: 'events/',
  webinars: 'webinars/',
  recipes: 'recipes/',
  partials: '@',
  customer_spotlights: 'customers/',
  reusable: '*',
};

export const makePhraseJobFileName = (type, slug) => {
  const phrasePrefix = phrasePrefixes[type] || '';
  return `${phrasePrefix}${slug}`;
};

function escapeRegexKey(string) {
  return string
    .replace(/[-\/\\^$*+?.()|[\]]/g, '\\$&')
    .replace(/["]/g, '\\\\$&');
}

export const replaceValues = (segmentDictionary, construct, isBlocks) => {
  Object.keys(segmentDictionary).forEach((key: any) => {
    let val = segmentDictionary[key];

    key = replaceUnicodes(key)?.trim();
    val = replaceUnicodes(val)?.trim();

    if (val) {
      key = new RegExp(`"${escapeRegexKey(key)}"([^:])`, 'g');
      val = `"${val.replace(/["]/g, '\\$&').replace(/\$/g, '$$$$')}"$1`;

      // This is test code
      if (isBlocks && !construct.match(key)) {
        console.log(`NO MATCH! ${val}`);
      }

      construct = construct.replace(key, val);
    }
  });

  return construct;
};

export const replaceUnicodes = (construct) => {
  if (!construct) {
    return '';
  }

  // @TODO Find a function to replace all unicodes to utf-8
  return construct
    .replace(/&amp;/g, '&')
    .replace(/\\u0026/g, '&')
    .replace(/\\u0026amp;/g, '&')
    .replace(/\\u00d7/g, '×')
    .replace(/\\u2018/g, '‘')
    .replace(/\\u2019/g, '’')
    .replace(/\\u201c/g, '“')
    .replace(/\\u201d/g, '”')
    .replace(/\\u2014/g, '—')
    .replace(/\\u0022/g, '"')
    .replace(/\\u003e/g, '>')
    .replace(/\\u003ca/g, '<')
    .replace(/\\\//g, '/');
};
